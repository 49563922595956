<template>
  <v-timeline
    side="end"
    density="compact"
    truncate-line="both"
    class="timeline profile"
  >
    <v-timeline-item
      v-if="loading"
      size="x-small"
      dot-color="primary"
    >
      <v-skeleton-loader
        class="mt-2"
        type="text"
      />
    </v-timeline-item>
    <v-timeline-item
      v-for="(note, index) in notes"
      :key="index"
      size="x-small"
      dot-color="primary"
    >
      <v-row
        no-gutters
        align="center"
      >
        <v-col>
          <div :style="maxWidth ? { 'max-width': maxWidth } : {}">
            <p class="text-caption text--secondary mb-1">
              {{ formatDate(note.date_created) }} um {{ formatTime(note.date_created) }} Uhr
            </p>
            <p class="text-body-2 text--primary">
              {{ note.text }}
            </p>
          </div>
        </v-col>
        <v-col
          class="text-right"
          cols="auto"
        >
          <v-btn
            icon
            variant="flat"
            @click="deleteNote(note.id)"
          >
            <v-icon color="error"> mdi-delete </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-timeline-item>
    <v-timeline-item
      size="x-small"
      dot-color="white"
      :hide-dot="!notes.length > 0"
      width="100%"
    >
      <v-text-field
        v-model="newNote"
        hide-details="auto"
        variant="outlined"
        density="compact"
        label="Notiz eingeben"
        data-cy="noteText"
        min-width="250px"
        @keyup.enter="saveNote"
      >
        <template #append>
          <v-btn
            data-cy="saveNote"
            color="primary"
            variant="elevated"
            rounded="xl"
            size="x-small"
            icon="mdi-content-save"
            @click="saveNote"
          >
          </v-btn>
        </template>
      </v-text-field>
    </v-timeline-item>
  </v-timeline>
</template>
<script>
export default {
  props: {
    collection: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: null,
    },
  },
  emits: ['add', 'delete'],
  data() {
    return {
      loading: false,
      notes: [],
      newNote: '',
    }
  },
  async mounted() {
    this.loading = true
    await this.$cms
      .request(
        this.$readItems('note', {
          filter: {
            reference: {
              ['item:' + this.collection]: {
                id: {
                  _eq: this.id,
                },
              },
            },
          },
          fields: [
            'id',
            'user_created',
            'date_created',
            'text',
            'reference.collection',
            'reference.item',
          ],
        })
      )
      .then((response) => {
        if (response) {
          this.notes = response
          this.loading = false
        }
      })
  },
  methods: {
    async saveNote() {
      if (this.newNote && this.newNote.length) {
        await this.$cms
          .request(
            this.$createItem(
              'note',
              {
                text: this.newNote,
                reference: [
                  {
                    collection: this.collection,
                    item: this.id,
                  },
                ],
              },
              {
                fields: [
                  'id',
                  'user_created',
                  'date_created',
                  'text',
                  'reference.collection',
                  'reference.item',
                ],
              }
            )
          )
          .then((response) => {
            this.notes.push(response)
            this.$emit('add')
            this.newNote = ''
          })
      }
    },
    async deleteNote(id) {
      await this.$cms.request(this.$deleteItem('note', id)).then(() => {
        this.notes = this.notes.filter((note) => note.id !== id)
        this.$emit('delete')
      })
    },
  },
}
</script>
<style lang="scss">
.timeline.profile {
  height: auto !important;
}
</style>
